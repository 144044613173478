<script setup lang="ts">
</script>
<template>
  <div
    class="about-us"
    id="about-us"
  >
    <div class="about-us-content">
      <div class="about-us-content-text">
        <h1 class="about-us-content-text-title">О компании</h1>
        <p class="about-us-content-text-description">
          Огромный опыт, штат квалифицированных <br>
          специалистов, передовые технологии рынка и <br>
          эксклюзивные собственные разработки позволяют <br>
          ООО "СЗ-ТЕХНОЛОДЖИ" на самом высоком уровне <br>
          оказывать своим клиентам весь комплекс услуг по <br>
          развитию бизнеса в Интернете.
        </p>
      </div>
      <div class="about-us-content-left">
        <img
          class="about-us-content-left-image"
          src="@/assets/images/Artboard-18.webp"
          alt="">
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.about-us {
  margin-top: get-vw(400px);
  @media (orientation: landscape){
    margin-top: get-vw(200px);
  }
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: get-vw(70px);
    margin-left: auto;
    margin-right: auto;
    @media (orientation: landscape){
      width: 65%;
    }
    &-left {
      width: 100%;
      &-image {
        margin-left: get-vw(-55px);
        height: 110%;
        width: 110%;
      }
    }
    &-text {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: get-vw(50px);
      &-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: get-vw(170px);
        line-height: get-vw(175px);
        color: #000000;
        position: relative;
        @media (orientation: landscape){
          font-size: get-vw(110px);
        }
      }
      &-title::before {
        content: '';
        display: block;
        position: absolute;
        //top: 110%;
        bottom: get-vw(-20px);
        width: 31%;
        border-bottom: get-vw(10px) solid #4C4EF9;
      }
      &-description {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: get-vw(65px);
        line-height: get-vw(90px);
        color: #000000;
        margin-top: get-vw(80px);
        white-space: nowrap;
        @media (orientation: landscape){
          font-size: get-vw(40px);
          line-height: get-vw(60px);
        }
      }
    }
  }
}
</style>
